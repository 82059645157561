<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="header"><span class="title">Buy Price List Products & Pricing</span></div>
      <div class="right"><el-input size="small" prefix-icon="el-icon-search" placeholder="Search for a variant (by Name,SKU,etc.)"></el-input></div>
    </div>
    <div class="filter-row">
      <div class="filter-row-button">
        <el-button class="button" size="small" @click="isVisible = !isVisible"><i class="el-icon-caret-bottom" :class="isVisible ? 'rotate' : ''"></i> Filter Products</el-button>
      </div>
      <div class="filter-row-info"><span>Note: Perform quick price updates by filtering to the items you wish to update and clicking Quick Update via Bulk Actions</span></div>
    </div>
    <div class="filters-container" v-if="isVisible">
      <div class="filters-content" v-for="(filter,index) in filters" :key="filter.id">
        <div class="col-1">
          <el-select v-model="value" placeholder="Select" size="small">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="col-2">
          <span style="font-weight: 1000">Is</span>
        </div>
        <div class="col-3">
          <el-input size="small"></el-input>
        </div>
        <div class="col-4">
          <el-button type="text" @click="removeFilter(index)"><i class="el-icon-delete" style="font-size: 17px; color: rgb(64, 158, 255);"></i></el-button>
        </div>
      </div>
      <div class="add-filter">
          <el-button type="text" @click="addFilter">
            <i class="el-icon-plus" style="margin-right: 2px; font-weight: 900"></i>
            <span class="add-filter-button">Add another Property</span>
          </el-button>
      </div>
      <div class="submit-filter">
        <el-button style="margin-right: 0px" @click="removeAllFilter()"><span>Reset Filters</span></el-button>
        <el-button style="margin-left: 0px"><span>Done</span></el-button>
        <span style="font-size: 14px; margin-left: 5px">{{ results }} result(s) in this filter set</span>
      </div>
    </div>
    <div class="body">
      <div class="options">
        <el-dropdown trigger="click" :hide-on-click="false">
          <div class="menu"><i class="el-icon-setting"></i></div>
          <el-dropdown-menu slot="dropdown">
            <span style="padding: 0 20px; font-size: 15px;">Show Columns</span>
            <el-dropdown-item v-for="field in fields" :key="field.label">
              <el-checkbox v-model="field.show">{{ field.label }}</el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table el-table style="width: 100%; height: 100%" height="100%" header-cell-class-name="cell-header" cell-class-name="cell-style">
        <el-table-column label="Product Name" min-width="180px">
        </el-table-column>
        <el-table-column label="Variant Name" min-width="180px">
        </el-table-column>
        <el-table-column label="SKU Code" min-width="180px">
        </el-table-column>
        <el-table-column label="Supplier" min-width="180px">
        </el-table-column>
        <el-table-column label="Retail (PHP)" min-width="180px">
        </el-table-column>
        <el-table-column label="Wholesale (PHP)" min-width="180px">
        </el-table-column>
        <el-table-column label="Buy (PHP)" min-width="180px">
        </el-table-column>
        <el-table-column label="Stock on Hand" min-width="180px">
        </el-table-column>
        <el-table-column label="Last Updated" min-width="180px">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.options {
  position: absolute;
  right: 5px;
  z-index: 1;
  margin-top: 4px;

  .menu {
    cursor: pointer;
    border: 1px solid #e2e2e2;
    padding: 5px;
    background: #fff;
    display: flex;
    align-items: center;

    i {
      font-size: 25px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .header-container {
    display: flex;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    align-items: center;
    margin: 10px 20px 20px 20px;
    justify-content: space-between;

    .right {
      flex-basis: 300px;
    }

    .title {
      color: #222;
      font-weight: 400;
      margin-right: 10px;
    }

    .subtitle {
      font-size: 14px;
      color: #a4aeb3;
    }
  }

  .filter-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    &-info {

      span {
        font-size: 13.5px;
      }

    }

    &-button {
      .button {
        i {
          transition: .3s all;
        }

        .rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .body {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;

    .el-form-item {
      width: 400px;
    }
  }

  .filters-container {
    padding: 20px 0px;

    .filters-content {
      display: flex;
      padding: 0px 20px;
      align-items: center;
      justify-content: flex-start;

      .col-1 {
        flex-basis: 200px;
      }

      .col-2 {
        margin-left: 10px;
        margin-right: 10px;
        flex-basis: 10px;
      }

      .col-3 {
        flex-basis: 90%;
      }

      .col-4 {
        margin: 0px 10px;

        .col-4:hover {
          color: #2200ff;
        }
      }
    }

    .add-filter {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;

    }

    .submit-filter {
      padding-left: 20px;
    }

  }
}
</style>

<script>
export default {
  data () {
    return {
      isVisible: false,
      filters: [],
      options: [
        {
          value: '',
          label: 'Brand'
        },
        {
          value: '',
          label: 'Supplier'
        }
      ],
      value: ''
    }
  },

  methods: {
    addFilter () {
      this.filters.push({ id: new Date() })
    },
    removeFilter (index) {
      this.filters.splice(index, 1)
    },
    removeAllFilter () {
      this.filters = []
      // for (let i = 0; i <= this.filters.length; i++) {
      //   this.filters.splice(i, 1)
      // }
    }
  },

  mounted () {
    this.$emit('update:active', 2)
  }

}
</script>
